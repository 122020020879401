const actions = {
	ADD_MEETING_REQUEST: 'ADD_MEETING_REQUEST',
	ADD_MEETING_REQUEST_SUCCESS: 'ADD_MEETING_REQUEST_SUCCESS',
	ADD_MEETING_REQUEST_ERROR: 'ADD_MEETING_REQUEST_ERROR',
	FETCH_MEETING_LIST_REQUEST: 'FETCH_MEETING_LIST_REQUEST',
	FETCH_MEETING_LIST_SUCCESS: 'FETCH_MEETING_LIST_SUCCESS',
	FETCH_MEETING_LIST_ERROR: 'FETCH_MEETING_LIST_ERROR',
	UPDATE_MEETING_REQUEST: 'UPDATE_MEETING_REQUEST',
	UPDATE_MEETING_REQUEST_SUCCESS: 'UPDATE_MEETING_REQUEST_SUCCESS',
	UPDATE_MEETING_REQUEST_ERROR: 'UPDATE_MEETING_REQUEST_ERROR',
	SET_SELECTED_MEETING: 'SET_SELECTED_MEETING',
	CHANGE_MEETING_EDIT_STATE: 'CHANGE_MEETING_EDIT_STATE',
	DELETE_MEETING_REQUEST: 'DELETE_MEETING_REQUEST',
	DELETE_MEETING_REQUEST_SUCCESS: 'DELETE_MEETING_REQUEST_SUCCESS',
	DELETE_MEETING_REQUEST_ERROR: 'DELETE_MEETING_REQUEST_ERROR',
	SET_MEETING_STATUS: 'SET_MEETING_STATUS',
	addMeeting: (data) => ({
		type: actions.ADD_MEETING_REQUEST,
		data: data,
	}),
	fetchMeeting: () => ({
		type: actions.FETCH_MEETING_LIST_REQUEST,
	}),
	updateMeeting: (data) => ({
		type: actions.UPDATE_MEETING_REQUEST,
		data: data,
	}),
	changeMeetingEditState: (state) => ({
		type: actions.CHANGE_MEETING_EDIT_STATE,
		data: state,
	}),
	deleteMeeting: (id) => ({
		type: actions.DELETE_MEETING_REQUEST,
		data: id,
	}),
	setMeetingStatus: (status) => ({
		type: actions.SET_MEETING_STATUS,
		data: status,
	}),
};
export default actions;
