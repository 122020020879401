const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	REGISTER_REQUEST: 'REGISTER_REQUEST',
	REGISTER_SUCCESS: 'REGISTER_SUCCESS',
	REGISTER_ERROR: 'REGISTER_ERROR',
	GET_USER_PROFILE_REQUEST: 'GET_USER_PROFILE_REQUEST',
	GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
	GET_USER_PROFILE_ERROR: 'GET_USER_PROFILE_ERROR',
	UPDATE_USER_PROFILE_REQUEST: 'UPDATE_USER_PROFILE_REQUEST',
	UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
	UPDATE_USER_PROFILE_ERROR: 'UPDATE_USER_PROFILE_ERROR',
	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
	login: authData => ({
		type: actions.LOGIN_REQUEST,
		email: authData.email,
		password: authData.password
	}),
	logout: () => ({
		type: actions.LOGOUT
	}),
	register: registerData => ({
		type: actions.REGISTER_REQUEST,
		data: registerData
	}),
	getUserProfile: () => ({
		type: actions.GET_USER_PROFILE_REQUEST
	}),
	updateUserProfile: userData => ({
		type: actions.UPDATE_USER_PROFILE_REQUEST,
		data: userData
	})
};
export default actions;
