import actions from './actions';

const initState = { reminders: [], addedNewReminders: false };
export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.FETCH_REMINDERS_REQUEST:
			return { ...state };
		case actions.FETCH_REMINDERS_REQUEST_SUCCESS:
			return { ...state, reminders: action.data };
		case actions.UPDATE_REMINDER_STATE:
			return { ...state, addedNewReminders: action.data };
		default:
			return state;
	}
}
