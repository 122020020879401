import actions from './actions';

const initState = { isLoading: false, token: null, profile: null };
export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_REQUEST:
			return { ...state, isLoading: true };
		case actions.LOGIN_SUCCESS:
			return { ...state, isLoading: false, token: action.token, profile: action.profile };
		case actions.LOGIN_ERROR:
			return { ...state, isLoading: false };
		case actions.REGISTER_REQUEST:
			return { ...state, isLoading: true };
		case actions.REGISTER_ERROR:
			return { ...state, isLoading: false };
		case actions.REGISTER_SUCCESS:
			return { ...state, isLoading: false };
		case actions.GET_USER_PROFILE_SUCCESS:
			return { ...state, profile: action.data };
		case actions.LOGOUT:
			return initState;
		default:
			return state;
	}
}
