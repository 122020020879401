import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import GlobalAxios from '../../helpers/GlobalAxios';
import { API_ENDPOINTS } from '../../models/Constants';
import { toast } from 'react-toastify';

export function* sagaMethods() {
	yield takeEvery(actions.FETCH_REMINDERS_REQUEST, function*(data) {
		try {
			const response = yield call(GlobalAxios.get, API_ENDPOINTS.FETCH_REMINDERS, data.data);
			yield put({
				type: actions.FETCH_REMINDERS_REQUEST_SUCCESS,
				data: response.data
			});
		} catch (e) {
			yield put({
				type: actions.FETCH_REMINDERS_REQUEST_ERROR
			});
			toast.error(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'There was an error fetching activity!');
		}
	});
}

export default function* rootSaga() {
	yield all([fork(sagaMethods)]);
}
