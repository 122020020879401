import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import GlobalAxios from "../../helpers/GlobalAxios";
import { API_ENDPOINTS } from "../../models/Constants";
import { toast } from "react-toastify";

export function* sagaMethods() {
    yield takeEvery(actions.SEARCH_REQUEST, function*(data) {
        try {
            const response = yield call(GlobalAxios.post, API_ENDPOINTS.SEARCH + "?keyword=" + data.keyword);
            yield put({
                type: actions.SEARCH_REQUEST_SUCCESS,
                result: response.data
            });
        } catch (e) {
            toast.error(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : "Hata!");
        }
    });
}

export default function* rootSaga() {
    yield all([fork(sagaMethods)]);
}
