import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import personActions from '../person/actions';
import whoActions from '../who/actions';
import tagAction from '../tag/actions';
import GlobalAxios from '../../helpers/GlobalAxios';
import { API_ENDPOINTS } from '../../models/Constants';
import { toast } from 'react-toastify';

export function* sagaMethods() {
	yield takeEvery(actions.ADD_MEETING_REQUEST, function* (data) {
		try {
			const response = yield call(GlobalAxios.post, API_ENDPOINTS.ADD_MEETING, data.data);
			/* eslint-disable */
			if (response.status == 200) {
				yield put({
					type: actions.ADD_MEETING_REQUEST_SUCCESS,
					data: response.data,
				});
				yield put({
					type: actions.FETCH_MEETING_LIST_REQUEST,
				});
				yield put({
					type: tagAction.FETCH_TAGLIST_REQUEST,
				});
			} else {
				yield put({
					type: actions.ADD_MEETING_REQUEST_ERROR,
				});
				toast.error('There was an error adding the meeting!');
			}
		} catch (e) {
			yield put({
				type: actions.ADD_MEETING_REQUEST_ERROR,
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'There was an error adding the meeting!'
			);
		}
	});

	yield takeEvery(actions.ADD_MEETING_REQUEST_SUCCESS, function* (data) {
		yield put({
			type: actions.SET_SELECTED_MEETING,
			data: data.data,
			status: 'updating',
		});
	});
	yield takeEvery(actions.SET_SELECTED_MEETING, function* (data) {
		yield put({
			type: actions.SET_MEETING_STATUS,
			data: data.status,
		});
	});
	yield takeEvery(actions.FETCH_MEETING_LIST_REQUEST, function* () {
		try {
			const response = yield call(GlobalAxios.get, API_ENDPOINTS.FETCH_MEETINGS);
			/* eslint-disable */
			yield put({
				type: actions.FETCH_MEETING_LIST_SUCCESS,
				data: response.data,
			});
		} catch (e) {
			yield put({
				type: actions.FETCH_MEETING_LIST_ERROR,
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'There was an error fetching meetings!'
			);
		}
	});

	yield takeEvery(actions.UPDATE_MEETING_REQUEST, function* (data) {
		try {
			var response = yield call(GlobalAxios.put, API_ENDPOINTS.UPDATE_MEETING, data.data);
			/* eslint-disable */
			yield put({
				type: actions.UPDATE_MEETING_REQUEST_SUCCESS,
				data: response.data,
			});
			yield put({
				type: actions.FETCH_MEETING_LIST_REQUEST,
			});
			yield put({
				type: personActions.FETCH_PERSONS_REQUEST,
			});
			yield put({
				type: tagAction.FETCH_TAGLIST_REQUEST,
			});
		} catch (e) {
			yield put({
				type: actions.UPDATE_MEETING_REQUEST_ERROR,
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'There was an error updating the meeting!'
			);
		}
	});

	yield takeEvery(actions.UPDATE_MEETING_REQUEST_SUCCESS, function* (data) {
		yield put({
			type: actions.SET_SELECTED_MEETING,
			data: data.data,
		});
	});

	yield takeEvery(actions.DELETE_MEETING_REQUEST, function* (data) {
		try {
			var response = yield call(GlobalAxios.delete, API_ENDPOINTS.DELETE_MEETING + '?id=' + data.data);
			/* eslint-disable */
			yield put({
				type: actions.DELETE_MEETING_REQUEST_SUCCESS,
				data: data.data,
			});
			toast.success('Meeting deleted!');
		} catch (e) {
			yield put({
				type: actions.DELETE_MEETING_REQUEST_ERROR,
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message ? e.response.data.message : 'There was an error updating the meeting!'
			);
		}
	});
}

export default function* rootSaga() {
	yield all([fork(sagaMethods)]);
}
