import App from './app/reducer';
import Auth from './auth/reducer';
import Meeting from './meeting/reducer';
import Person from './person/reducer';
import Who from './who/reducer';
import Tag from './tag/reducer';
import Search from './search/reducer';
import Activity from './activity/reducer';

export default {
	App,
	Auth,
	Meeting,
	Person,
	Who,
	Tag,
	Search,
	Activity
};
