import actions from "./actions";

const initState = { searchResult: null };
export default function searchReducer(state = initState, action) {
    switch (action.type) {
        case actions.SEARCH_REQUEST_SUCCESS:
            return { ...state, searchResult: action.result };
        default:
            return state;
    }
}
