import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";
import { ThemeProvider } from "styled-components";
import theme from "./settings/theme";
import AppHolder from "./App.style";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { history, store } from "./redux/store";
import Boot from "./redux/boot";
import { ConnectedRouter } from "connected-react-router";
//import AuthHelper from "./helpers/AuthHelper";
import router from "./router";
import Loadable from "react-loadable";
import AuthHelper from "./helpers/AuthHelper";

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div />;

// All layouts/containers
const NonAuthLayout = Loadable({
    loader: () => import("./components/Layout/NonAuthLayout"),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading
});

const AuthLayout = Loadable({
    loader: () => import("./components/Layout/AuthLayout"),
    render(loaded, props) {
        let Component = loaded.default;
        return <Component {...props} />;
    },
    loading
});
const App = () => {
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <AppHolder>
                    <ConnectedRouter history={history}>
                        <Switch>
                            {router.map((route, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        roles={route.roles}
                                        render={props => {
                                            if (route.isPublic) {
                                                return (
                                                    <Suspense fallback={loading()}>
                                                        <NonAuthLayout {...props} title={route.title}>
                                                            <route.component {...props} />
                                                        </NonAuthLayout>
                                                    </Suspense>
                                                );
                                            }
                                            const isAuthTokenValid = AuthHelper.isUserAuthenticated();
                                            if (!isAuthTokenValid) {
                                                // not logged in so redirect to login page with the return url
                                                return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
                                            }
                                            return (
                                                <Suspense fallback={loading()}>
                                                    <AuthLayout {...props} title={route.title}>
                                                        <route.component {...props} />
                                                    </AuthLayout>
                                                </Suspense>
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </ConnectedRouter>
                    <ToastContainer draggable={false} hideProgressBar={true} />
                </AppHolder>
            </ThemeProvider>
        </Provider>
    );
};
Boot()
    .then(() => App())
    .catch(error => console.error(error));
export default App;
