import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import GlobalAxios from "../../helpers/GlobalAxios";
import { API_ENDPOINTS } from "../../models/Constants";
import { toast } from "react-toastify";

export function* sagaMethods() {
    yield takeEvery(actions.FETCH_TAGLIST_REQUEST, function*() {
        try {
            const response = yield call(GlobalAxios.get, API_ENDPOINTS.FETCH_TAGLIST);
            yield put({
                type: actions.FETCH_TAGLIST_SUCCESS,
                data: response.data
            });
        } catch (e) {
            yield put({
                type: actions.FETCH_TAGLIST_ERROR
            });
            toast.error(e && e.response && e.response.data && e.response.data.message ? e.response.data.message : "There was an unexpected fetching the tags!");
        }
    });
}

export default function* rootSaga() {
    yield all([fork(sagaMethods)]);
}
