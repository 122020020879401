import actions from "./actions";

const initState = { tags: [] };
export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_TAGLIST_SUCCESS:
            return { ...state, tags: action.data };
        default:
            return state;
    }
}
