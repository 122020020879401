import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import actions from './actions';
import GlobalAxios from '../../helpers/GlobalAxios';
import { API_ENDPOINTS } from '../../models/Constants';
import { toast } from 'react-toastify';
import AuthHelper from '../../helpers/AuthHelper';

export function* sagaMethods() {
	yield takeEvery(actions.LOGIN_REQUEST, function*(authData) {
		const data = {
			password: authData.password,
			email: authData.email
		};
		try {
			const response = yield call(GlobalAxios.post, API_ENDPOINTS.LOGIN, data);
			yield put({
				type: actions.LOGIN_SUCCESS,
				token: response.data.token,
				profile: {
					name: response.data.name,
					avatar: response.data.avatar,
					email: response.data.email
				}
			});
			toast.success('Başarıyla giriş yapıldı!');
			yield put(push('/meeting'));
		} catch (e) {
			yield put({ type: actions.LOGIN_ERROR });
			toast.error(
				e && e.response && e.response.data && e.response.data.message
					? e.response.data.message
					: 'Giriş başarısız! Lütfen bilgileri tekrar deneyiniz!'
			);
		}
	});
	yield takeEvery(actions.LOGIN_SUCCESS, function*(authData) {
		yield localStorage.setItem('jwtToken', authData.token);
		yield localStorage.setItem('profile', JSON.stringify(authData.profile));
		yield put(push('/'));
	});
	yield takeEvery(actions.LOGOUT, function*() {
		AuthHelper.clearToken();
		yield put(push('/login'));
	});
	/* eslint-disable */
	yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
		const token = AuthHelper.getToken().get('jwtToken');
		if (token) {
			if (!AuthHelper.isUserAuthenticated()) {
				toast.error('Your session has expired!');
				AuthHelper.clearToken();
				yield put(push('/login'));
			}
		}
	});
	yield takeEvery(actions.REGISTER_REQUEST, function*(formData) {
		try {
			yield call(GlobalAxios.post, API_ENDPOINTS.REGISTER, formData.data);
			yield put({
				type: actions.REGISTER_SUCCESS
			});
			toast.success('Registration is success!');
			yield put(push('/login'));
		} catch (e) {
			toast.error('Error!');
			yield put({ type: actions.REGISTER_ERROR });
		}
	});
	yield takeEvery(actions.REGISTER_SUCCESS, function*() {});
	yield takeEvery(actions.GET_USER_PROFILE_REQUEST, function*() {
		try {
			var response = yield call(GlobalAxios.get, API_ENDPOINTS.GET_USER_PROFILE);
			yield put({
				type: actions.GET_USER_PROFILE_SUCCESS,
				data: response.data
			});
		} catch (e) {
			toast.error('Error!');
			yield put({ type: actions.GET_USER_PROFILE_ERROR });
		}
	});
	yield takeEvery(actions.UPDATE_USER_PROFILE_REQUEST, function*(formData) {
		try {
			yield call(GlobalAxios.put, API_ENDPOINTS.UPDATE_USER_PROFILE, formData.data);
			yield put({
				type: actions.UPDATE_USER_PROFILE_SUCCESS
			});
			yield put({
				type: actions.GET_USER_PROFILE_REQUEST
			});
			toast.success('Profile succesfully updated!');
		} catch (e) {
			toast.error('Error!');
			yield put({ type: actions.UPDATE_USER_PROFILE_ERROR });
		}
	});
}

export default function* rootSaga() {
	yield all([fork(sagaMethods)]);
}
