import actions from "./actions";

const initState = { persons: [], selectedMeeting: null };
export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_PERSONS_WITH_MEETING_SUCCESS:
            return { ...state, persons: action.data };
        case actions.FETCH_MEETING_SUCCESS:
            return { ...state, selectedMeeting: action.data };
        case actions.REMOVE_SELECTED_MEETING:
            return { ...state, selectedMeeting: null };
        default:
            return state;
    }
}
