import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import GlobalAxios from "../../helpers/GlobalAxios";
import { API_ENDPOINTS } from "../../models/Constants";
import { toast } from "react-toastify";

export function* sagaMethods() {
    yield takeEvery(actions.FETCH_PERSONS_REQUEST, function*(data) {
        try {
            const response = yield call(GlobalAxios.post, API_ENDPOINTS.FETCH_PERSONS, data.data);
            /* eslint-disable */
            if (response.status == 200) {
                yield put({
                    type: actions.FETCH_PERSONS_REQUEST_SUCCESS,
                    data: response.data
                });
            } else {
                yield put({
                    type: actions.FETCH_PERSONS_REQUEST_ERROR
                });
                toast.error("There was an error fetching person list!");
            }
        } catch (e) {
            yield put({
                type: actions.FETCH_PERSONS_REQUEST_ERROR
            });
            toast.error(
                e && e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : "There was an unexpected fetching the persons!"
            );
        }
    });
}

export default function* rootSaga() {
    yield all([fork(sagaMethods)]);
}
