import actions from './actions';
import whoActions from '../who/actions';

const initState = { meetings: [], selectedMeeting: null, isEditing: false, meetingStatus: 'waiting' };
export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.ADD_MEETING_REQUEST:
			return { ...state };
		case actions.FETCH_MEETING_LIST_SUCCESS:
			return { ...state, meetings: action.data };
		case whoActions.FETCH_MEETING_SUCCESS:
			return { ...state, selectedMeeting: action.data };
		case actions.SET_SELECTED_MEETING:
			return { ...state, selectedMeeting: action.data, isEditing: true };
		case actions.SET_MEETING_STATUS:
			return { ...state, meetingStatus: action.data };
		case actions.CHANGE_MEETING_EDIT_STATE:
			return { ...state, isEditing: action.data };
		case whoActions.REMOVE_SELECTED_MEETING:
			return { ...state, selectedMeeting: null };
		case actions.DELETE_MEETING_REQUEST_SUCCESS: {
			let meetingArray = state.meetings.meetings;
			if (meetingArray && meetingArray.length > 0) {
				meetingArray.map((value, index) => {
					if (value.id === action.data) {
						meetingArray.splice(index, 1);
					}
					return meetingArray;
				});
			}
			return {
				selectedMeeting: meetingArray[0],
				isEditing: false,
				meetings: {
					meetings: meetingArray,
				},
			};
		}
		default:
			return state;
	}
}
