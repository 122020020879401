const actions = {
	FETCH_REMINDERS_REQUEST: 'FETCH_REMINDERS_REQUEST',
	FETCH_REMINDERS_REQUEST_SUCCESS: 'FETCH_REMINDERS_REQUEST_SUCCESS',
	FETCH_REMINDERS_REQUEST_ERROR: 'FETCH_REMINDERS_REQUEST_ERROR',
	UPDATE_REMINDER_STATE: 'UPDATE_REMINDER_STATE',
	fetchReminders: () => ({
		type: actions.FETCH_REMINDERS_REQUEST
	}),
	updateReminderState: state => ({
		type: actions.UPDATE_REMINDER_STATE,
		data: state
	})
};
export default actions;
