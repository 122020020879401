const actions = {
    FETCH_PERSONS_WITH_MEETING_REQUEST: "FETCH_PERSONS_WITH_MEETING_REQUEST",
    FETCH_PERSONS_WITH_MEETING_SUCCESS: "FETCH_PERSONS_WITH_MEETING_SUCCESS",
    FETCH_PERSONS_WITH_MEETING_ERROR: "FETCH_PERSONS_WITH_MEETING_ERROR",
    FETCH_MEETING_REQUEST: "FETCH_MEETING_REQUEST",
    FETCH_MEETING_SUCCESS: "FETCH_MEETING_SUCCESS",
    FETCH_MEETING_ERROR: "FETCH_MEETING_ERROR",
    ADD_PERSON_REQUEST: "ADD_PERSON_REQUEST",
    ADD_PERSON_SUCCESS: "ADD_PERSON_SUCCESS",
    ADD_PERSON_ERROR: "ADD_PERSON_ERROR",
    UPDATE_PERSON_REQUEST: "UPDATE_PERSON_REQUEST",
    UPDATE_PERSON_SUCCESS: "UPDATE_PERSON_SUCCESS",
    UPDATE_PERSON_ERROR: "UPDATE_PERSON_ERROR",
    REMOVE_SELECTED_MEETING: "REMOVE_SELECTED_MEETING",
    fetchPersonsWithMeeting: () => ({
        type: actions.FETCH_PERSONS_WITH_MEETING_REQUEST
    }),
    fetchMeetingById: id => ({
        type: actions.FETCH_MEETING_REQUEST,
        data: id
    }),
    removeSelectedMeeting: () => ({
        type: actions.REMOVE_SELECTED_MEETING
    }),
    addPerson: data => ({
        type: actions.ADD_PERSON_REQUEST,
        data: data
    }),
    updatePerson: data => ({
        type: actions.UPDATE_PERSON_REQUEST,
        data: data
    })
};
export default actions;
