import React from "react";

export default [
    {
        path: "/",
        name: "Home",
        component: React.lazy(() => import("./containers/Home/Home")),
        isPublic: true,
        exact: true
    },
    {
        path: "/login",
        name: "Login",
        component: React.lazy(() => import("./containers/Login/Login")),
        isPublic: true,
        exact: true
    },
    {
        path: "/register",
        name: "Register",
        component: React.lazy(() => import("./containers/Register/Register")),
        isPublic: true,
        exact: true
    },
    // {
    //     path: '/register-confirm',
    //     name: 'Register Confirm',
    //     component: React.lazy(() => import('./containers/Register/RegisterConfirm')),
    //     isPublic: true,
    //     exact: true
    // },
    // {
    //     path: '/register/:token',
    //     name: 'Register Confirm',
    //     component: React.lazy(() => import('./containers/Register/RegisterConfirm')),
    //     isPublic: true,
    //     exact: true
    // },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: React.lazy(() => import("./containers/Dashboard/Dashboard")),
        isPublic: false,
        exact: true
    },
    {
        path: "/meeting",
        name: "Meeting",
        component: React.lazy(() => import("./containers/Meeting/Meeting")),
        isPublic: false,
        exact: true
    },
    {
        path: "/meetingDetail/:id",
        name: "Meeting Detail",
        component: React.lazy(() => import("./containers/Meeting/MeetingDetail")),
        isPublic: false,
        exact: true
    },
    {
        path: "/who",
        name: "Who",
        component: React.lazy(() => import("./containers/Who/Who")),
        isPublic: false,
        exact: true
    },
    {
        path: "/activity",
        name: "Activity",
        component: React.lazy(() => import("./containers/Activity/Activity")),
        isPublic: false,
        exact: true
    },
    {
        path: "/where",
        name: "Where",
        component: React.lazy(() => import("./containers/Where/Where")),
        isPublic: false,
        exact: true
    },
    {
        path: "/when",
        name: "When",
        component: React.lazy(() => import("./containers/When/When")),
        isPublic: false,
        exact: true
    },
    {
        path: "/how",
        name: "How",
        component: React.lazy(() => import("./containers/How/How")),
        isPublic: false,
        exact: true
    },
    {
        path: "/settings",
        name: "Settings",
        component: React.lazy(() => import("./containers/Settings/Settings")),
        isPublic: false,
        exact: true
    }
];
