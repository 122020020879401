const API_ENDPOINTS = {
	LOGIN: '/authentication/login',
	REGISTER: '/authentication/register',
	ADD_MEETING: '/meeting/add',
	FETCH_MEETINGS: '/meeting/GetMeetingList' /*GetItems*/,
	UPDATE_MEETING: '/meeting/update',
	FETCH_PERSONS: '/person/PersonList',
	GET_USER_PROFILE: '/user/GetUserProfile',
	UPDATE_USER_PROFILE: 'user/UpdateUserProfile',
	FETCH_PERSONS_WITH_MEETING: '/person/GetPersonsWithMeeting',
	FETCH_MEETING: '/meeting/getMeetingById',
	ADD_PERSON: '/person/add',
	UPDATE_PERSON: '/person/update',
	FETCH_TAGLIST: '/tag/TagList',
	SEARCH: '/meeting/Search',
	DELETE_MEETING: '/meeting/delete',
	FETCH_REMINDERS: '/activity/GetReminders',
};
const MeetingTypes = [
	{
		label: 'Facetoface',
		value: 'facetoface',
	},
	{
		label: 'Phone',
		value: 'phone',
	},
	{
		label: 'Text',
		value: 'text',
	},
	{
		label: 'Email',
		value: 'email',
	},
	{
		label: 'Teleconference',
		value: 'teleconference',
	},
	{
		label: 'Other',
		value: 'other',
	},
];
export { API_ENDPOINTS, MeetingTypes };
