import { all } from 'redux-saga/effects';
import appSagas from './app/saga';
import authSagas from './auth/saga';
import meetingSagas from './meeting/saga';
import personSagas from './person/saga';
import whoSagas from './who/saga';
import tagSagas from './tag/saga';
import searchSagas from './search/saga';
import activitySagas from './activity/saga';

export default function* rootSaga(getState) {
	yield all([appSagas(), authSagas(), meetingSagas(), personSagas(), whoSagas(), tagSagas(), searchSagas(), activitySagas()]);
}
