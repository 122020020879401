import actions from "./actions";
const initState = {
    currentMenu: null
};
export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.CHANGE_CURRENT_MENU:
            return { ...state, currentMenu: action.currentMenu };
        default:
            return state;
    }
}
