import axios from 'axios';
import settings from '../settings/config';
import Auth from '../helpers/AuthHelper';
import https from 'https';

// const instance = axios.create({
// 	baseURL: settings.apiUrl,
// });
const instance = axios.create();
const agent = new https.Agent({
	rejectUnauthorized: false,
});
instance.interceptors.request.use(
	(config) => {
		if (!config.headers['Authorization']) {
			config.headers['Authorization'] = `Bearer ${Auth.getToken().get('jwtToken')}`;
		}
		if (process.env.dev) {
			config.httpsAgent = agent;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

export default instance;
