import actions from './actions';

const initState = { persons: null };
export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.FETCH_PERSONS_REQUEST:
			return { ...state };
		case actions.FETCH_PERSONS_REQUEST_SUCCESS:
			return { ...state, persons: action.data };
		default:
			return state;
	}
}
