import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import tagAction from '../tag/actions';
import GlobalAxios from '../../helpers/GlobalAxios';
import { API_ENDPOINTS } from '../../models/Constants';
import { toast } from 'react-toastify';

export function* sagaMethods() {
	yield takeEvery(actions.FETCH_PERSONS_WITH_MEETING_REQUEST, function*() {
		try {
			const response = yield call(GlobalAxios.get, API_ENDPOINTS.FETCH_PERSONS_WITH_MEETING);
			yield put({
				type: actions.FETCH_PERSONS_WITH_MEETING_SUCCESS,
				data: response.data
			});
		} catch (e) {
			yield put({
				type: actions.FETCH_PERSONS_WITH_MEETING_ERROR
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message
					? e.response.data.message
					: 'There was an unexpected fetching the persons!'
			);
		}
	});

	yield takeEvery(actions.FETCH_MEETING_REQUEST, function*(data) {
		try {
			const response = yield call(GlobalAxios.get, API_ENDPOINTS.FETCH_MEETING + '?id=' + data.data);
			yield put({
				type: actions.FETCH_MEETING_SUCCESS,
				data: response.data
			});
		} catch (e) {
			yield put({
				type: actions.FETCH_MEETING_ERROR
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message
					? e.response.data.message
					: 'There was an unexpected fetching the meeting!'
			);
		}
	});

	yield takeEvery(actions.ADD_PERSON_REQUEST, function*(data) {
		try {
			const response = yield call(GlobalAxios.post, API_ENDPOINTS.ADD_PERSON, data.data);
			yield put({
				type: actions.ADD_PERSON_SUCCESS,
				data: response.data
			});
			yield put({
				type: actions.FETCH_PERSONS_WITH_MEETING_REQUEST
			});
			yield put({
				type: tagAction.FETCH_TAGLIST_REQUEST
			});
			toast.success('Person added!');
		} catch (e) {
			yield put({
				type: actions.ADD_PERSON_ERROR
			});

			toast.error(
				e && e.response && e.response.data && e.response.data.message
					? e.response.data.message
					: 'There was an unexpected error adding the persons!'
			);
		}
	});

	yield takeEvery(actions.UPDATE_PERSON_REQUEST, function*(data) {
		try {
			const response = yield call(GlobalAxios.put, API_ENDPOINTS.UPDATE_PERSON, data.data);
			yield put({
				type: actions.UPDATE_PERSON_SUCCESS,
				data: response.data
			});
			yield put({
				type: actions.FETCH_PERSONS_WITH_MEETING_REQUEST
			});
			yield put({
				type: tagAction.FETCH_TAGLIST_REQUEST
			});
			toast.success('Person updated!');
		} catch (e) {
			yield put({
				type: actions.UPDATE_PERSON_ERROR
			});
			toast.error(
				e && e.response && e.response.data && e.response.data.message
					? e.response.data.message
					: 'There was an unexpected error updating the persons!'
			);
		}
	});
}

export default function* rootSaga() {
	yield all([fork(sagaMethods)]);
}
