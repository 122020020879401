import jwtDecode from "jwt-decode";
import { Map } from "immutable";

class AuthHelper {
    getUserProfile = () => {
        const profile = localStorage.getItem("profile");
        if (profile) {
            return JSON.parse(profile);
        }
    };

    isUserAuthenticated = () => {
        const token = this.getToken().get("jwtToken");
        if (token) {
            const decoded = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    clearToken = () => {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("profile");
    };
    getToken() {
        try {
            const jwtToken = localStorage.getItem("jwtToken");
            return new Map({ jwtToken });
        } catch (err) {
            console.log("error while getting token", err);
            this.clearToken();
            return new Map();
        }
    }
    setToken = token => {
        localStorage.setItem("jwtToken", token);
    };
}
export default new AuthHelper();
