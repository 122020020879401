import "./Localization"; //this must be first line
import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/nucleo.css";
import "./assets/css/all.min.css";
import "./assets/css/argon-dashboard-react.min.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./custom.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
